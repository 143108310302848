<template>
  <div>
    <div class="tway-header-1">PMO Digital</div>
    <div style="display: flex; flex-direction: row">
      <span class="tway-header-2 mr-3">Proyecto Integración de Automatizaciones para Auditoría</span>
      <div class="roboto alert-pmo">PROYECTO FINALIZADO</div>
    </div>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <v-card outlined style="border-color: #731ad5; border-radius: 20px" class="fill-height pa-5">
          <v-row class="pa-0 ma-0 mb-3" align="center">
            <v-img :src="require('@/assets/img/pmo/roadmap.svg')" class="mr-3" min-width="40px" max-width="40px" />
            <strong>Roadmap y Avances</strong>
          </v-row>
          <v-row class="px-2 py-1 ma-0 mb-3" align="center">
            <div style="display: flex">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Project #</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Priority</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Complete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in projects" :key="item.id" @click="goPage()">
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.priority }}</td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.complete }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
          <v-row
            class="px-2 py-1 ma-0"
            align="center"
            justify="space-between"
            style="background: rgba(255, 255, 255, 0.25); border: 2px solid #ffffff; border-radius: 35px"
          >
            <v-img :src="require('@/assets/img/pmo/reloj.svg')" color="white" min-width="40px" max-width="40px" />
            <h3 class="roboto mr-3">100%</h3>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card outlined style="border-color: #731ad5; border-radius: 20px" class="fill-height pa-5">
          <v-row class="pa-0 ma-0 mb-3" align="center">
            <v-img :src="require('@/assets/img/pmo/kpi.svg')" class="mr-3" min-width="40px" max-width="40px" />
            <strong class="tway-violet--text">3 Principales KPI´s</strong>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="4" class="pa-0 ma-0 mt-8">
              <div style="display: flex; flex-direction: column">
                <strong class="mb-2" style="color: #333333; font-size: 16px">Crecimiento</strong>
                <strong class="mb-2" style="color: #333333; font-size: 16px">Satisfacción</strong>
                <strong style="color: #333333; font-size: 16px">ROI</strong>
              </div>
            </v-col>
            <v-col cols="8" class="pa-0 ma-0" align="center" justify-self="center">
              <v-row class="pa-0 ma-0" style="border-bottom: 1px solid #16c62e">
                <v-col class="pa-0 ma-0">
                  <small style="color: #16c62e">Esperado</small>
                </v-col>
                <v-col class="pa-0 ma-0">
                  <small style="color: #16c62e">Actual</small>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <div
                    style="
                      border-right: 1px solid #16c62e;
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <strong
                      style="
                        width: 100%;
                        color: #999999;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <span class="mt-1">$425 M</span>
                    </strong>
                    <strong
                      style="
                        width: 100%;
                        color: #999999;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <span class="mt-1">+17%</span>
                    </strong>
                    <strong
                      style="
                        width: 100%;
                        color: #999999;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <span class="mt-1">+25%</span>
                    </strong>
                  </div>
                </v-col>
                <v-col class="pa-0 ma-0">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <strong
                      style="
                        width: 100%;
                        color: #7319d5;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                      "
                    >
                      <span class="mr-1 mt-1">$210 M</span>
                      <v-img
                        :src="require('@/assets/img/pmo/check.svg')"
                        class="mr-3"
                        min-width="18px"
                        max-width="18px"
                      />
                    </strong>
                    <strong
                      style="
                        width: 100%;
                        color: #7319d5;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                      "
                    >
                      <span class="mr-1 mt-1">+15%</span>
                      <v-img
                        :src="require('@/assets/img/pmo/check.svg')"
                        class="mr-3"
                        min-width="18px"
                        max-width="18px"
                      />
                    </strong>
                    <strong
                      style="
                        width: 100%;
                        color: #7319d5;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                      "
                    >
                      <span class="mr-1 mt-1">+20%</span>
                      <v-img
                        :src="require('@/assets/img/pmo/check.svg')"
                        class="mr-3"
                        min-width="18px"
                        max-width="18px"
                      />
                    </strong>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card outlined style="border-color: #731ad5; border-radius: 20px" class="fill-height pa-5">
          <v-row class="pa-0 ma-0 mb-3" align="center">
            <v-img :src="require('@/assets/img/pmo/riesgos.svg')" class="mr-3" min-width="40px" max-width="40px" />
            <strong class="tway-violet--text">Riesgos</strong>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0" cols="10">
              <div style="display: flex; flex-direction: column">
                <strong style="color: #333333; font-size: 16px">• Aumento leve de presupuesto</strong>
                <strong style="color: #333333; font-size: 16px">• Definir actividades extra</strong>
                <strong style="color: #333333; font-size: 16px">• Solicitudes nuevas de cambio</strong>
              </div>
            </v-col>
            <v-col cols="2" class="pa-0 ma-0" align="center" justify-self="center">
              <strong style="color: #fdd835; font-size: 64px">3</strong>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card outlined style="border-color: #731ad5; border-radius: 20px" class="fill-height pa-5">
          <v-row class="pa-0 ma-0 mb-3" align="center">
            <v-img :src="require('@/assets/img/pmo/presupuesto.svg')" class="mr-3" min-width="40px" max-width="40px" />
            <strong class="tway-violet--text">Presupuesto</strong>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" class="pa-0 ma-0">
              <div style="display: flex; flex-direction: column">
                <span style="color: #333333; font-size: 16px">Presupuesto actual:</span>
                <strong class="mb-2" style="color: #333333; font-size: 16px">1.124 UF</strong>
                <span style="color: #333333; font-size: 16px">Presupuesto original:</span>
                <strong style="color: #333333; font-size: 16px">1.000 UF</strong>
              </div>
            </v-col>
            <v-col cols="6" class="pa-0 ma-0" align="center" justify-self="center">
              <strong style="color: #d50000; font-size: 50px">+16%</strong>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card outlined style="border-color: #731ad5; border-radius: 20px" class="fill-height pa-5">
          <v-row class="pa-0 ma-0 mb-3" align="center">
            <v-img :src="require('@/assets/img/pmo/fechas.svg')" class="mr-3" min-width="40px" max-width="40px" />
            <strong class="tway-violet--text">Fechas</strong>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col cols="8" class="pa-0 ma-0">
              <div style="display: flex; flex-direction: column">
                <strong style="color: #333333; font-size: 16px">15/01/2021:</strong>
                <strong class="mb-2" style="color: #333333; font-size: 16px">Término de proyecto</strong>
                <span style="color: #333333; font-size: 16px">Estimación inicial: 6 meses</span>
                <span style="color: #333333; font-size: 16px">Estimación actual: 5 meses</span>
              </div>
            </v-col>
            <v-col cols="4" class="pa-0 ma-0" align="center" justify-self="center">
              <strong style="color: #16c62e; font-size: 50px">+5%</strong>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card outlined style="border-color: #731ad5; border-radius: 20px" class="fill-height pa-5">
          <v-row class="pa-0 ma-0 mb-3" align="center">
            <strong class="tway-violet--text">Otros Proyectos con PMO Digital</strong>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0" cols="12">
              <div style="display: flex; flex-direction: column">
                <span class="chip-pmo mb-2">Ciberseguridad en Equipos</span>
                <span class="chip-pmo mb-2">Omnicanalidad en RR.HH.</span>
                <v-btn outlined rounded style="color: #16c62e; border: 1px solid #16c62e" class="roboto">
                  <strong>CONTRATAR CUPO ADICIONAL</strong>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <v-card outlined style="border-color: #731ad5; border-radius: 20px" class="fill-height pa-5">
          <v-row class="pa-0 ma-0 mb-3" align="center">
            <v-img :src="require('@/assets/img/pmo/status.svg')" class="mr-3" min-width="40px" max-width="40px" />
            <strong class="tway-violet--text">Status PMO Digital</strong>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0" cols="12">
              <div style="display: flex; flex-direction: column">
                <span
                  style="
                    color: #333333;
                    font-size: 16px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                  "
                >
                  <strong>Análisis de documentos anexos del proyecto</strong>
                  <div
                    style="
                      width: 50%;
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <v-progress-linear
                      color="#16C62E"
                      v-model="full"
                      class="mx-2"
                      rounded
                      style="border-radius: 10px; max-width: 180px"
                      height="25"
                    />
                    <strong style="color: #7319d5" class="mr-2">
                      <h3>100%</h3>
                    </strong>
                    <a style="color: #7319d5; text-decoration: underline"> Ver avance </a>
                  </div>
                </span>
                <span
                  style="
                    color: #333333;
                    font-size: 16px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                  "
                >
                  <strong>Verificación de metodologías de ejecución</strong>
                  <div
                    style="
                      width: 50%;
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <v-progress-linear
                      color="#16C62E"
                      v-model="full"
                      class="mx-2"
                      rounded
                      style="border-radius: 10px; max-width: 180px"
                      height="25"
                    />
                    <strong style="color: #7319d5" class="mr-2">
                      <h3>100%</h3>
                    </strong>
                    <a style="color: #7319d5; text-decoration: underline"> Ver avance </a>
                  </div>
                </span>
                <span
                  style="
                    color: #333333;
                    font-size: 16px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                  "
                >
                  <strong>Análisis de posibles riesgos adicionales</strong>
                  <div
                    style="
                      width: 50%;
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      align-items: center;
                    "
                  >
                    <v-progress-linear
                      color="#16C62E"
                      v-model="full"
                      class="mx-2"
                      rounded
                      style="border-radius: 10px; max-width: 180px"
                      height="25"
                    />
                    <strong style="color: #7319d5" class="mr-2">
                      <h3>100%</h3>
                    </strong>
                    <a style="color: #7319d5; text-decoration: underline"> Ver avance </a>
                  </div>
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" justify="center">
      <v-btn rounded color="tway-violet" class="roboto white--text mt-3">
        <strong>REALIZAR EVALUACIÓN A PMO DIGITAL</strong>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      full: 100,
      projects: [
        {
          id: 1,
          name: "Canal de Ventas Online",
          priority: "High",
          status: "In process",
          complete: "25%",
        },
        {
          id: 2,
          name: "Rediseño Servicio Clientes",
          priority: "Normal",
          status: "In process",
          complete: "25%",
        },
        {
          id: 3,
          name: "Implentación Safe",
          priority: "High",
          status: "In process",
          complete: "35%",
        },
        {
          id: 4,
          name: "Model Engagment",
          priority: "Low",
          status: "Not Started",
          complete: "0%",
        },
      ],
    };
  },
  methods: {
    goPage() {},
  },
};
</script>

<style scoped>
.alert-pmo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  color: #16c62e;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Verde Manzana / Verde Manzana 4 */

  background: #c5f1cb;
  border-radius: 4px;
}
.chip-pmo {
  background: #dcc5f4;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: fit-content;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Tway / Violeta */

  color: #7319d5;
}
</style>
